<template>
    <div class="uk-card uk-card-default uk-card-body uk-border-rounded">
        <div class="uk-flex uk-flex-between uk-flex-center uk-flex-middle">
            <div class="uk-flex uk-flex-center uk-flex-middle">
                <div>
                    <img v-if="!photoUrl" :data-src="`${images}/avatar.svg`" alt="Avatar Icon" uk-img class="photo">
                    <img v-else-if="photoUrl" :data-src="photoUrl" @error="(e) => imgError(e)" alt="Avatar Icon" uk-img class="photo">
                </div>
                <div class="uk-margin-medium-left" style="color: black">
                    <p class="uk-margin-remove uk-text-bold">{{ fullname || '-' }}</p>
                    <p class="uk-margin-remove">Last Company: {{ lastCompany  || '-' }}</p>
                    <p class="uk-margin-remove">Last working date: {{ formatDate(lastWorkingDate) || '-' }}</p>
                    <p class="uk-margin-remove">Last Position: {{ lastPosition || '-' }}</p>
                    <p class="uk-margin-remove">Last Office: {{ lastOffice  || '-' }}</p>
                    <p class="uk-margin-remove">Reasons: {{ checkReason({ reasons }) || '-' }}</p>
                    <p class="uk-margin-remove">Notes: {{ notes || '-' }}</p>
                    <p class="uk-margin-remove">Duration as Mitra MyRobin: {{ checkDate || '-' }}</p>
                    <p class="uk-margin-remove">Location: {{ location || '-' }}</p>
                </div>
            </div>
            <div 
                v-if="$can('READ', 'ex-mitra') || $can('WRITE', 'ex-mitra') || $can('EDIT', 'ex-mitra')"
                class="uk-flex uk-flex-column" style="gap: 10px"
            >
                <button
                    v-if="$can('EDIT', 'ex-mitra')"
                    class="uk-button uk-button-default uk-border-rounded"
                    style="
                        width: 221px; 
                        background-color: #0ABAB5; 
                        color: white;
                    "
                    @click="$emit('re-active')"
                >
                    Re-Active
                </button>
                <button
                    v-if="$can('WRITE', 'ex-mitra')"
                    class="uk-button uk-button-primary uk-border-rounded"
                    style="width: 221px;"
                    @click="$emit('auto-assign')"
                >
                    Auto Assign
                </button>
                <button
                    v-if="$can('READ', 'ex-mitra')"
                    class="uk-button uk-button-default uk-border-rounded"
                    style="
                        width: 221px;
                        border: 1px solid #0275d8;
                        color: #0275d8;
                    "
                    @click="$emit('cv-details')"
                >
                    CV Details
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import formatter from "@/utils/formatter";
import { PREFIX_IMAGE } from "@/utils/constant";
import { DateTime } from 'luxon';

export default {
    name: 'ExMitraItem',
    props: {
        photoUrl: {
            type: String,
            required: false,
            default: null
        },
        fullname: {
            type: String,
            required: false,
            default: null
        },
        lastCompany: {
            type: String,
            required: false,
            default: null
        },
        lastOffice: {
            type: String,
            required: false,
            default: null
        },
        lastPosition: {
            type: String,
            required: false,
            default: null
        },
        lastWorkingDate: {
            type: String,
            required: false,
            default: null
        },
        reasons: {
            type: Number,
            required: false,
            default: null
        },
        notes: {
            type: String,
            required: false,
            default: null
        },
        durationAsMitra: {
            type: Number,
            required: false,
            default: null
        },
        location: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            dateTime: DateTime
        };
    },
    computed: {
        checkDate() {
            if (this.durationAsMitra > 365) {
                return 'More than one year';
            } else {
                if (this.durationAsMitra > 30) {
                    return `${Math.round(this.durationAsMitra / 30)} months`;
                } else if (this.durationAsMitra > 0) {
                    return `${this.durationAsMitra} days`;
                } else {
                    return 'Less than one day';
                }
            }
        }
    },
    methods: {
        formatDate(data) {
            if (data === null) {
                return "-";
            }
            return formatter.dateComplete(data);
        },
        checkReason({ reasons }) {
            switch (reasons) {
            case 0:
                return 'Habis Kontrak';
            case 1: 
                return 'Sakit';
            case 2:
                return 'Selesai Event';
            case 3:
                return 'Blacklist - Tak Tertoleransi';
            case 4:
                return 'Blacklist - Tertoleransi';
            case 5:
                return 'Lainnya';
            }
        },
        imgError(e) {
            e.target.src = `${this.images}/avatar.svg`;
        }        
    }
};
</script>

<style scoped>
.photo {
    width: 106px; 
    height: 106px; 
    border-radius: 100%; 
    object-fit: cover;
    object-position: center;
}
</style>
